.product-detail-container {
    padding: 80px 5% 2rem;
    max-width: 1400px;
    margin: 0 auto;
}

/* Breadcrumb */
.product-breadcrumb {
    margin-bottom: 2rem;
    color: #666;
}

.product-breadcrumb a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s;
}

.product-breadcrumb a:hover {
    color: #ff4d4d;
}

/* Product Detail */
.product-detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-bottom: 4rem;
}

/* Product Gallery */
.product-gallery {
    position: sticky;
    top: 100px;
}

.main-image {
    width: 100%;
    aspect-ratio: 3/4;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 1.5rem;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.main-image:hover img {
    transform: scale(1.05);
}

.thumbnail-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.thumbnail {
    aspect-ratio: 1;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s ease;
    border: 2px solid transparent;
}

.thumbnail.active {
    opacity: 1;
    border-color: #ff4d4d;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Product Info */
.product-info {
    padding-top: 2rem;
}

.product-title {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.product-meta {
    display: flex;
    gap: 2rem;
    color: #666;
    margin-bottom: 1.5rem;
}

.product-price {
    font-size: 2rem;
    color: #ff4d4d;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.product-description {
    color: #666;
    line-height: 1.8;
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

/* Product Options */
.product-options {
    margin-bottom: 2rem;
}

.size-selection, .quantity-selection {
    margin-bottom: 1.5rem;
}

.size-selection h3, .quantity-selection h3 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #333;
}

.size-options {
    display: flex;
    gap: 1rem;
}

.size-option {
    width: 50px;
    height: 50px;
    border: 2px solid #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    transition: all 0.3s ease;
    font-weight: 500;
}

.size-option:hover {
    border-color: #ff4d4d;
    color: #ff4d4d;
}

.size-option.active {
    background: #ff4d4d;
    color: white;
    border-color: #ff4d4d;
}

.quantity-input {
    display: flex;
    align-items: center;
    max-width: 150px;
}

.quantity-btn {
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    background: none;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    border-radius: 8px;
}

.quantity-btn:hover {
    background: #f5f5f5;
}

.quantity-input input {
    width: 60px;
    height: 40px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 1rem;
    margin: 0 0.5rem;
    border-radius: 8px;
}

/* Product Actions */
.product-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2rem;
}

.add-to-cart-btn, .buy-now-btn {
    padding: 1.2rem;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.add-to-cart-btn {
    background: white;
    border: 2px solid #ff4d4d;
    color: #ff4d4d;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.add-to-cart-btn:hover {
    background: #ff4d4d;
    color: white;
    transform: translateY(-2px);
}

.buy-now-btn {
    background: #ff4d4d;
    color: white;
}

.buy-now-btn:hover {
    background: #ff3333;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255,77,77,0.3);
}

/* Product Features */
.product-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 15px;
    margin-top: 2rem;
}

.feature {
    text-align: center;
}

.feature svg {
    font-size: 2rem;
    color: #ff4d4d;
    margin-bottom: 1rem;
}

.feature span {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.4;
}

/* Product Tabs */
.product-tabs {
    margin-top: 4rem;
}

.tab-buttons {
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 2rem;
}

.tab-btn {
    padding: 1rem 2rem;
    background: none;
    border: none;
    font-size: 1.1rem;
    font-weight: 500;
    color: #666;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.tab-btn::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ff4d4d;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.tab-btn.active {
    color: #ff4d4d;
}

.tab-btn.active::after {
    transform: scaleX(1);
}

.tab-content {
    padding: 2rem 0;
}

.tab-pane {
    display: none;
    animation: fadeIn 0.3s ease-out;
}

.tab-pane.active {
    display: block;
}

/* Related Products */
.related-products {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 1px solid #eee;
}

.related-products h2 {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    margin-bottom: 2rem;
}

/* Responsive Design */
@media (max-width: 992px) {
    .product-detail {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .product-gallery {
        position: static;
    }
}

@media (max-width: 768px) {
    .product-features {
        grid-template-columns: 1fr;
    }

    .product-actions {
        grid-template-columns: 1fr;
    }

    .tab-buttons {
        flex-wrap: wrap;
    }

    .tab-btn {
        flex: 1;
        padding: 1rem;
        font-size: 1rem;
    }
}

/* Notification */

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Description Tab */
.product-description-content h3,
.specifications-content h3,
.size-guide-content h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
}

.description-text {
    color: #666;
    line-height: 1.8;
}

.description-text h4 {
    color: #2c3e50;
    font-size: 1.2rem;
    margin: 1.5rem 0 1rem;
}

.description-text ul {
    list-style: none;
    padding-left: 0;
}

.description-text ul li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.8rem;
}

.description-text ul li::before {
    content: '•';
    color: #ff4d4d;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
}

/* Specifications Tab */
.specs-table {
    width: 100%;
    border-collapse: collapse;
}

.specs-table tr {
    border-bottom: 1px solid #eef2f7;
}

.specs-table td {
    padding: 1rem;
    color: #666;
}

.specs-table td:first-child {
    font-weight: 500;
    color: #2c3e50;
    width: 200px;
    background: #f8f9fa;
}

/* Size Guide Tab */
.measurement-guide {
    margin-bottom: 2rem;
}

.measurement-guide h4 {
    color: #2c3e50;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.measurement-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.step {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 10px;
}

.step i {
    font-size: 1.5rem;
    color: #ff4d4d;
}

.step p {
    color: #666;
    margin: 0;
}

.size-table {
    margin: 2rem 0;
}

.size-table h4 {
    color: #2c3e50;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.size-table table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    border-radius: 10px;
    overflow: hidden;
}

.size-table th {
    background: #f8f9fa;
    color: #2c3e50;
    font-weight: 600;
    padding: 1rem;
    text-align: center;
}

.size-table td {
    padding: 1rem;
    text-align: center;
    color: #666;
    border-bottom: 1px solid #eef2f7;
}

.size-table tr:last-child td {
    border-bottom: none;
}

.size-notes {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 10px;
}

.size-notes h4 {
    color: #2c3e50;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.size-notes ul {
    list-style: none;
    padding-left: 0;
}

.size-notes ul li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.8rem;
    color: #666;
}

.size-notes ul li::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #ff4d4d;
    position: absolute;
    left: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .measurement-steps {
        grid-template-columns: 1fr;
    }

    .size-table {
        overflow-x: auto;
    }

    .specs-table td:first-child {
        width: 150px;
    }
} 