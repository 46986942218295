/* Thêm vào đầu file */
h1, h2, h3, h4 {
    font-family: 'Playfair Display', serif;
}

/* Container styles */
.checkout-container {
    padding: 100px 5% 3rem;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.checkout-content {
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 2.5rem;
}

/* Form section styles */
.checkout-form {
    background: white;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.05);
    padding: 2.5rem;
}

.checkout-form h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #f8f9fa;
    letter-spacing: 0.5px;
}

.form-section {
    margin-bottom: 2.5rem;
    background: #fff;
    padding: 1.5rem;
    border-radius: 15px;
    border: 1px solid #eef2f7;
}

.form-section h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.4rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.form-section h3::before {
    content: '';
    width: 4px;
    height: 24px;
    background: #ff4d4d;
    border-radius: 2px;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.8rem;
    color: #34495e;
    font-weight: 500;
    font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 1rem 1.2rem;
    border: 1.5px solid #e0e0e0;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f8f9fa;
}

.form-group textarea {
    height: 120px;
    resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #ff4d4d;
    background: #fff;
    box-shadow: 0 0 0 4px rgba(255,77,77,0.1);
}

/* Payment methods styles */
.payment-methods {
    display: grid;
    gap: 1rem;
}

.payment-method {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border: 1.5px solid #e0e0e0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #f8f9fa;
}

.payment-method:hover {
    border-color: #ff4d4d;
    background-color: #fff;
    transform: translateY(-2px);
}

.payment-method input[type="radio"] {
    width: 20px;
    height: 20px;
    accent-color: #ff4d4d;
}

.payment-method span {
    font-weight: 500;
    color: #2c3e50;
    font-size: 1.1rem;
}

/* Order summary styles */
.order-summary {
    background: white;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.05);
    padding: 2.5rem;
    height: auto;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    position: sticky;
    top: 100px;
}

.order-summary h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #f8f9fa;
}

/* Tùy chỉnh thanh cuộn cho order summary */
.order-summary::-webkit-scrollbar {
    width: 6px;
}

.order-summary::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.order-summary::-webkit-scrollbar-thumb {
    background: #ff4d4d;
    border-radius: 3px;
}

/* Firefox scrollbar */
.order-summary {
    scrollbar-width: thin;
    scrollbar-color: #ff4d4d #f1f1f1;
}

/* Cart items styles */
.cart-items {
    margin-bottom: 2rem;
}

.cart-items::-webkit-scrollbar {
    width: 6px;
}

.cart-items::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.cart-items::-webkit-scrollbar-thumb {
    background: #ff4d4d;
    border-radius: 3px;
}

.cart-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid #eef2f7;
    transition: all 0.3s ease;
}

.cart-item:hover {
    background: #f8f9fa;
    border-radius: 12px;
}

.item-image {
    width: 100px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #eef2f7;
}

.item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.cart-item:hover .item-image img {
    transform: scale(1.05);
}

.item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item-details h4 {
    font-size: 1.1rem;
    color: #2c3e50;
    font-weight: 600;
}

.item-meta {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.item-size {
    color: #666;
    background: #f8f9fa;
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
    display: inline-block;
    font-size: 0.9rem;
    border: 1px solid #eef2f7;
}

.item-price {
    color: #ff4d4d;
    font-weight: 600;
    font-size: 1.1rem;
}

.item-quantity {
    color: #666;
    font-size: 0.95rem;
}

.item-total {
    font-weight: 600;
    color: #ff4d4d;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

/* Order total styles */
.order-total {
    margin-top: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 12px;
}

.order-total > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: #666;
    font-size: 1.05rem;
}

.order-total .total {
    font-size: 1.3rem;
    color: #2c3e50;
    font-weight: 600;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid #eef2f7;
}

.order-total .total .amount {
    color: #ff4d4d;
    font-size: 1.5rem;
}

/* Submit button styles */
.cta-button {
    width: 100%;
    padding: 1.3rem;
    background: #ff4d4d;
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 2rem;
}

.cta-button:hover {
    background: #ff3333;
    transform: translateY(-2px);
    box-shadow: 0 5px 20px rgba(255,77,77,0.3);
}

/* Bank info styles */
.bank-info {
    margin-top: 1.5rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 12px;
    border: 1px solid #eef2f7;
}

.bank-info h4 {
    font-family: 'Playfair Display', serif;
    color: #2c3e50;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.bank-info p {
    margin-bottom: 0.8rem;
    color: #666;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.bank-info p strong {
    color: #2c3e50;
    min-width: 120px;
}

/* Responsive styles */
@media (max-width: 1400px) {
    .checkout-content {
        grid-template-columns: 1fr;
    }

    .order-summary {
        position: static;
        max-height: none;
        overflow: visible;
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .checkout-container {
        padding: 80px 1rem 2rem;
    }

    .checkout-form,
    .order-summary {
        padding: 1.5rem;
    }

    .cart-item {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .item-image {
        margin: 0 auto;
    }

    .item-total {
        justify-content: center;
    }
} 