/* Thêm vào đầu file */
h1, h2, h3, h4 {
    font-family: 'Playfair Display', serif;
}

.auth-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: #f5f5f5;
}

.auth-box {
    width: 100%;
    max-width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 2rem;
}

.auth-box h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.auth-tabs {
    display: flex;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee;
}

.auth-tab {
    flex: 1;
    padding: 1rem;
    border: none;
    background: none;
    font-size: 1rem;
    font-weight: bold;
    color: #666;
    cursor: pointer;
    transition: all 0.3s;
}

.auth-tab.active {
    color: #ff4d4d;
    border-bottom: 2px solid #ff4d4d;
}

.auth-form {
    display: none;
}

.auth-form.active {
    display: block;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

.form-group input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: normal;
}

.checkbox-label input {
    width: auto;
}

.forgot-password {
    float: right;
    color: #ff4d4d;
    text-decoration: none;
    font-size: 0.9rem;
}

.auth-button {
    width: 100%;
    padding: 1rem;
    background: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
}

.auth-button:hover {
    background: #ff3333;
}

@media (max-width: 480px) {
    .auth-box {
        padding: 1rem;
    }
} 